import FooterWidget from './FooterWidget';
import './style.css';
import Logo from 'assets/img/logo.png';
import { siteConfiguration } from 'siteConfig';
import { useLanguageContext } from 'context/LanguageProvider';

const Footer = () => {
	const { footerLogoURL, footer } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<footer>
			<div className="footer-container wrapper">
				<div className="footer-logo-container">
					<a href={footerLogoURL}>
						<img className="footer-logo" src={Logo} alt='SotM Africa Logo'></img>
					</a>
				</div>
				<div className="wrapper-links">
					{footer
						.filter((footerItem) => footerItem.isActive)
						.map((footerItem, id) => (
							<FooterWidget
								key={id}
								widgetTitle={footerItem.title[currentLanguage]}
								WidgetList={footerItem.links}
							/>
						))}
				</div>
			</div>
			<div className="copyright-wrapper">
				<p>
					<span>Built with</span>
					<span role="img" aria-label="love">❤️</span>
				</p>
				<p className="copyright">
					{siteConfiguration.copyright[currentLanguage]}
				</p>
			</div>
		</footer>
	);
};

export default Footer;
