import React from 'react';
import './style.css';
import { siteConfiguration } from 'siteConfig';
import { ReactComponent as Line } from '../../assets/split-line.svg';
import Button from 'components/Button/Button';
import { useLanguageContext } from 'context/LanguageProvider';
import Countdown from 'react-countdown';
const Jumbotron = () => {
	const {
		conferenceYear,
		conferenceTheme,
		conferenceDate,
		conferenceLocation,
		heroButtons,
	} = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<div className="jumbotron-container wrapper">
			<div className="jumbotron-content">
				<h2 className="conference-year">{conferenceYear[currentLanguage]}</h2>
				<h1 className="conference-theme">{conferenceTheme[currentLanguage]}</h1>
			</div>
			<div className="conference-time-location">
				<span>{conferenceDate[currentLanguage]}</span>
				<Line className="line" />
				<span>{conferenceLocation}</span>
				<Line className="line" />
				<div className='countdown'>
					<Countdown date={new Date(2025, 11, 28)} renderer={renderer} />
				</div>
			</div>
			<div className="jumbotron-buttons">
				{heroButtons.map((button, index) => (
					<Button
						text={button.text[currentLanguage]}
						showArrow={button.showArrowIcon}
						arrowColor={button.arrowColor}
						route={button.route}
						key={`hero-button-${index}`}
						isURL={button.isURL}
					/>
				))}

			</div>
		</div>
	);
};

export default Jumbotron;



// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
	if (completed) {
		return <span>SotM Africa 2025 is live🎉!</span>;
	} else {
		// Render a countdown
		return <span>{days} days to go. {hours}: {minutes} :{seconds}</span>;
	}
};