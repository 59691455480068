import About from 'components/About/About';
import Registration from 'components/Registration/Registration';
import Activity from 'components/Activity/Activity';
import Venue from 'components/Venue/Venue';
import './style.css';
import SEO from 'pages/SEO';

import Sponsors from 'components/Sponsors/Sponsors';
import Header from 'components/Header/Header';
import Jumbotron from 'components/Jumbotron/Jumbotron';

import { siteConfiguration } from 'siteConfig';
import Map from 'components/Map/Map';

export default function HomePage() {
	const { heroBackgroundImage } = siteConfiguration;
	return (
		<div
			style={{
				overflow: 'hidden',
				width: '100%',
			}}
		>
			<SEO
				title={'Home'}
				url={'/'}
				description={
					'The State of the Map Africa (SotM Africa) is a bi-annual regional conference that celebrates the culture of open mapping, open data, GIS and its impact across Africa.'
				}
			/>
			<div className="hompage-top-section">
				<img
					src={heroBackgroundImage.image}
					alt={heroBackgroundImage.alt}
					title={heroBackgroundImage.alt}
					className="home-bg-picture"
				/>
				<Header activateWhite={true} />
				<Jumbotron />
			</div>

			<div className="wrapper homepage">

				<About />
				<Registration />
				<Activity />
				<Venue />
				<div>
					<Map />
				</div>
				<Sponsors />
			</div>
		</div>
	);
}
