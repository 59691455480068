import Header from 'components/Header/Header';
import MarkdownLoader from 'components/MarkdownLoader';

import { useLanguageContext } from 'context/LanguageProvider';
import NotFound from 'pages/NotFound/NotFound';
import React from 'react';
import { useParams } from 'react-router-dom';
import { siteConfiguration } from 'siteConfig';
import './style.css';
import SEO from 'pages/SEO';
const BlogDetails = () => {
	const { blogSlug } = useParams();
	const { blogs } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();

	//handle invalid slugs
	const validSlug = blogs[currentLanguage].find(
		(blog) => blog.route === `/blog/${blogSlug}`
	);
	if (!validSlug) {
		return <NotFound />;
	}

	//grab the blog object
	const articleDetail = blogs[currentLanguage].filter(
		(article) => article.route === `/blog/${blogSlug}`
	)[0];

	return (
		<>
			<SEO title={articleDetail.title} url={`/blog/${blogSlug}`} />
			<Header />
			<div className="wrapper blog-detail-page">
				<button
					onClick={() => window.history.back()}
					style={{
						padding: '10px 20px',
						backgroundColor: '#000',
						color: '#fff',
						border: 'none',
						borderRadius: '5px',
						cursor: 'pointer',
						marginTop: "100px",
						width: 'fit-content'
					}}>Go Back</button>
				<img src={articleDetail.image} alt={articleDetail.title}></img>
				<div>
					<h1>{articleDetail.title} </h1>
					<h3>Posted: {articleDetail.date}</h3>
				</div>
				<MarkdownLoader page={blogSlug} />
			</div>
		</>
	);
};

export default BlogDetails;
