import conference from './assets/img/conference.jpg';
import community from './assets/img/community.jpg';
import venue1 from './assets/img/venue1.jpg';
import venue2 from './assets/img/venue2.jpg';
import maskimage from './assets/img/mask-group.png';
import workshop from './assets/img/workshop.jpg';
import participants from './assets/img/participants.jpg';


//page contents
import AboutMdEn from 'contents/about/about.md';
import SponsorEn from 'contents/sponsor/sponsor.md';
import CallsEn from 'contents/calls/calls.md';
import CodeOfConductEn from 'contents/code-of-conduct.md';
import AboutMdFr from 'contents/about/about_fr.md';
import SponsorFr from 'contents/sponsor/sponsor_fr.md';
import CallsFr from 'contents/calls/calls_fr.md';
import CodeOfConductFr from 'contents/code-of-conduct_fr.md';
import VenueFr from 'contents/venue/attending_fr.md';
import VenueEn from 'contents/venue/attending.md';

//sponsors
import OMDTZLogo from './assets/sponsors/Original_OMDTZ_Logo.png';

//blogs
import BlogVenueAnnouncement from 'contents/blogs/venue-announcement.md';
import BlogVenueAnnouncementFr from 'contents/blogs/venue-announcement_fr.md';

export const siteConfiguration = {
	//Conference Information, Landing Page/Hero

	conferenceYear: {
		en: 'SotM Africa 2025',
		fr: 'SotM Afrique 2025',
	},
	conferenceTheme: {
		en: 'Mapping The Future: Connecting Communities and Fostering Collaboration.',
		fr: 'Cartographier l\'avenir : connecter les communautés et favoriser la collaboration.',
	},
	heroBackgroundImage: {
		image: venue2,
		alt: "Attribution: Arnold Tibaijuka"
	},
	conferenceDate: {
		en: '28 - 30th Nov., 2025',
		fr: '28 - 30th Nov.,  2025',
	},
	conferenceLocation: 'Dar es Salaam, Tanzania.',

	//About

	aboutTitle: {
		en: 'About SotM Africa',
		fr: 'À propos de SotM Africa',
	},
	aboutContent: {
		en: `The State of the Map Africa (SotM Africa) is a bi-annual regional conference that celebrates the culture of open mapping, open data, GIS and its impact across Africa. The first SotM Africa conference was hosted by the OSM community in Uganda in 2017.

  This year’s conference in Dar es Salaam, Tanzania will continue to build on the strategy envisioned for OpenStreetMap in Africa as a renewed, strong, and growing network, and as part of the global OpenStreetMap and Open GIS movement.
  `,
		fr: `La conférence State of the Map Africa (SotM Africa) est une conférence régionale organisée tous les deux ans qui se consacre à la mise en valeur de la "cartographie ouverte", des "données ouvertes", des SIG et de leur impact à travers l'Afrique. La première conférence SotM Africa a été organisée par la communauté OSM en Ouganda en 2017.

	La conférence de cette année se tiendra à Dar es Salaam, au Tanzania, et continuera à s'appuyer sur la stratégie envisagée pour OpenStreetMap en Afrique en tant que réseau renouvelé, fort et en pleine croissance, et en tant que partie du mouvement mondial OpenStreetMap et de celui des SIG ouverts.`,
	},
	aboutButton: {
		text: {
			en: 'Learn more',
			fr: 'En savoir plus',
		},
		showArrowIcon: true,
		route: '/about',
		arrowColor: '#fff',
	},

	//Registration
	registrationTitle: {
		en: `Registration`,
		fr: `Inscription`,
	},
	registrationContent: {
		en: `Registration information is coming soon. Stay tuned for updates!`,
		fr: `Les informations relatives à l'inscription seront bientôt disponibles. Restez à l'écoute pour les mises à jour !`,
	},
	ticketTitle: {
		en: ``,
		fr: ``,
	},
	earlyBirdEndDate: {
		en: ``,
		fr: ``,
	},

	//Navigation

	navigationLinks: [
		{
			text: {
				en: 'About',
				fr: 'A propos',
			},
			route: '/about',
			isActive: true,
		},
		{
			text: {
				en: 'Sponsor',
				fr: 'Sponsor',
			},
			route: '/sponsor',
			isActive: false,
		},
		{
			text: { en: 'Programme', fr: 'Programme' },
			route: '/programme',
			isActive: false,
		},
		{
			text: { en: 'Blog', fr: 'Blog' },
			route: '/blog',
			isActive: true,
		},

	],
	navigationButtons: [
		{
			text: {
				en: 'Suggest a Theme',
				fr: 'Inscription',
			},
			route:
				'https://bit.ly/SotMAfrica2025Theme',
			isActive: false,
			showArrowIcon: true,
			arrowColor: '#fff',
		},
	],
	heroButtons: [
		{
			text: {
				en: 'Venue Announcement',
				fr: 'Annonce du lieu',
			},
			route: 'https://www.youtube.com/watch?v=Jht3q7elj9o',
			isActive: true,
			showArrowIcon: true,
			arrowColor: '#fff',
			isURL: true,
		},
	],
	// Activities
	activityTitle: {
		en: `SotM Africa 2025 will be a 3 days of learning, sharing and fun!`,
		fr: `SotM Afrique 2025 sera une expérience d'apprentissage, de partage et d'amusement de 3 jours!`,
	},

	activities: [
		{
			activityPhoto: `${conference}`,
			activityName: {
				en: 'Conference',
				fr: 'Conférence',
			},
			activityContent: {
				en: `SOTM Africa will bring together participants from around the world to discuss challenges, learn, and celebrate many years of mapping together.`,
				fr: `SOTM Africa regroupera des participants du monde entier pour discuter des défis, apprendre et célébrer de nombreuses années de cartographie ensemble.`,
			},
		},
		{
			activityPhoto: `${workshop}`,
			activityName: {
				en: 'Workshops',
				fr: 'Ateliers',
			},
			activityContent: {
				en: `Featuring full-day of workshops, hands-on experience coupled with learning and sharing!`,
				fr: `Au programme, des ateliers tout au long de la journée, une expérience pratique associée à l'apprentissage et au partage !`,
			},
		},
		{
			activityPhoto: `${community}`,
			activityName: {
				en: 'Community',
				fr: 'Communauté',
			},
			activityContent: {
				en: `The community will meet and have fun!`,
				fr: `Toute la communauté se réunira et prendra du plaisir !`,
			},
		},
	],
	// Venue
	venueTitle: {
		en: 'Venue',
		fr: 'Lieu',
	},

	venueContent: {
		en: 'The conference is scheduled to be hosted at University of Dar es Salaam, the oldest and biggest public university in Tanzania. The venue is located in the western side of Dar es Salaam occupying 1,625 acres on the observation hill, 13 kilometers from the city center. For Visa information, accommodation and more, please click the button below.',
		fr: "La conférence se tiendra à l'Université de Dar es Salaam, la plus ancienne et la plus grande université publique de Tanzanie. Le lieu est situé à l'ouest de Dar es Salaam, sur une colline d'observation de 1 625 acres, à 13 kilomètres du centre-ville. Pour obtenir des informations sur les visas, l'hébergement et plus encore, veuillez cliquer sur le bouton ci-dessous.",
	},
	venueButton: {
		text: {
			en: 'Explore Dar es Salaam',
			fr: 'Visiter Dar es Salaam',
		},
		showArrowIcon: true,
		route: '/venue',
		arrowColor: '#fff',
	},
	venuePhotos: [
		{
			title: 'Conference Hall',
			path: `${venue1}`,
		},
		// {
		// 	title: 'Yaounde Mall',
		// 	path: `${venue2}`,
		// },
	],
	//Pricing

	conferenceTickets: [
		// {
		// 	ticketName: {
		// 		en: 'Africa',
		// 		fr: 'Africa',
		// 	},
		// 	ticketUSDPrice: '75',
		// 	ticketDescription: {
		// 		en: 'Participants from Africa',
		// 		fr: "Participants d'Afrique",
		// 	},
		// 	ticketBtnText: {
		// 		en: 'Buy Ticket',
		// 		fr: 'Acheter un billet',
		// 	},
		// 	ticketLink:
		// 		'https://www.eventbrite.com/e/state-of-the-map-africa-2023-tickets-657695694527',
		// },
		// {
		// 	ticketName: {
		// 		en: 'Global',
		// 		fr: 'Global',
		// 	},
		// 	ticketUSDPrice: '150',

		// 	ticketDescription: {
		// 		en: 'Participants from other continents',
		// 		fr: 'Participants from other continents',
		// 	},
		// 	ticketBtnText: {
		// 		en: 'Buy Ticket',
		// 		fr: 'Acheter un billet',
		// 	},

		// 	ticketLink:
		// 		'https://www.eventbrite.com/e/state-of-the-map-africa-2023-tickets-657695694527',
		// },
		// {
		// 	ticketName: {
		// 		en: 'Business',
		// 		fr: 'Business',
		// 	},
		// 	ticketUSDPrice: '350',

		// 	ticketDescription: {
		// 		en: 'For Organizations',
		// 		fr: 'For Organizations',
		// 	},
		// 	ticketBtnText: {
		// 		en: 'Buy Ticket',
		// 		fr: 'Acheter un billet',
		// 	},

		// 	ticketLink:
		// 		'https://www.eventbrite.com/e/state-of-the-map-africa-2023-tickets-657695694527',
		// },
	],

	// Constant website marketing
	WebsiteMarketing: {
		marketingContent: {
			en: 'A conference for OpenStreetMap enthusiasts from all over Africa.',
			fr: "Une conférence pour les passionnés d'OpenStreetMap de toute l'Afrique.",
		},
		picture: maskimage,
	},

	//Map
	Map: {
		LatLng: [-6.7816, 39.20567],
		location: 'Dar es Salaam',
		city: 'Dar es Salaam',
		route: '/venue',
	},

	//Subscribe

	Subscribe: {
		info: {
			en: 'Stay updated on everything SotM Africa',
			fr: 'Restez au courant de toutes les activités de SotM Africa',
		},
		link: 'https://eepurl.com/itbD7k',
		btnName: {
			en: 'Subscribe',
			fr: "S'abonner",
		},
	},

	//language
	Languages: [
		{
			name: 'English',
			symbol: 'en',
		},
		{
			name: 'French',
			symbol: 'fr',
		},
	],

	//Sponsor

	AllSponsor: [
		{
			package: {
				en: 'Platinum',
				fr: 'Platine',
			},
			color: '#F0900A',
			sponsorsInfo: [
				{
					name: {
						en: 'Your logo here',
						fr: 'Your logo here',
					},
					logo: "",
				},
			],
		},
		{
			package: {
				en: 'Gold',
				fr: 'Or',
			},
			color: '#FFD700',
			sponsorsInfo: [
				{
					name: {
						en: 'Your logo here',
						fr: 'Your logo here',
					},
					logo: "",
				},
			],
		},
		{
			package: {
				en: 'Silver',
				fr: 'Argent',
			},
			color: '#C0C0C0',
			sponsorsInfo: [
				{
					name: {
						en: 'Your logo here',
						fr: 'Your logo here',
					},
					logo: "",
				},
			],
		},

		{
			package: {
				en: 'Bronze',
				fr: 'Bronze',
			},
			color: '#CD7F32',
			sponsorsInfo: [
				{
					name: {
						en: 'Your logo here',
						fr: 'Your logo here',
					},
					logo: "",
				},
			],
		},
		{
			package: {
				en: 'Supporter',
				fr: 'Supporter',
			},
			color: '#000',
			sponsorsInfo: [
				{
					name: {
						en: 'OpenMap Development Tanzania',
						fr: 'OpenMap Development Tanzania',
					},
					logo: OMDTZLogo,
					url: 'https://www.omdtz.or.tz',
				},
			],
		},
	],

	// Sponsorship Prospectus
	sponsorProspectusText: {
		en: 'Coming soon: Your support will help us to increase diversity within the OSM community and improve OSM contribution and use within the continent.',
		fr: "Bientôt disponible : Votre soutien nous aidera à accroître la diversité au sein de la communauté OSM et à améliorer la contribution à et l'utilisation d'OSM sur le continent.",
	},

	//Footer

	footer: [
		{
			title: {
				en: 'SotM Africa',
				fr: 'SotM Afrique',
			},
			isActive: true,
			links: [
				{
					text: {
						en: 'About',
						fr: 'A propos',
					},
					link: '/about',
					isURL: false,
				},
				// {
				// 	text: { en: 'Blog', fr: 'Blog' },
				// 	link: '/blog',
				// 	isURL: false,
				// },
				// {
				// 	text: { en: 'Sponsor', fr: 'Sponsor' },
				// 	link: '/Sponsor',
				// 	isURL: false,
				// },
				// {
				// 	text: { en: 'Programme', fr: 'Programme' },
				// 	link: '/programme',
				// 	isURL: false,
				// },
			],
		},
		{
			title: {
				en: 'Quick Links',
				fr: 'Liens utiles',
			},
			isActive: true,
			links: [
				// {
				// 	text: {
				// 		en: 'Call for Speakers',
				// 		fr: 'Appel aux intervenants',
				// 	},
				// 	link: '/calls',
				// 	isURL: false,
				// },
				// {
				// 	text: {
				// 		en: 'Call for Scholarship',
				// 		fr: 'Demande de bourse',
				// 	},
				// 	link: 'https://forms.gle/w9NcUNYyyoieo37o8',
				// 	isURL: true,
				// },
				{
					text: {
						en: 'Explore Dar es Salaam',
						fr: 'Visiter Dar es Salaam',
					},
					link: '/venue',
					isURL: false,
				},
				// {
				// 	text: 'Travel Grant',
				// 	link: '',
				// 	isURL: false,
				// },
			],
		},
		{
			title: {
				en: 'Follow us',
				fr: 'Suivez-nous',
			},
			isActive: true,
			links: [
				{
					text: {
						en: 'Twitter',
						fr: 'Twitter',
					},
					link: 'https://twitter.com/sotmafrica',
					isURL: true,
				},
				{
					text: { en: 'Facebook', fr: 'Facebook' },
					link: 'https://www.facebook.com/sotmafrica/?_rdc=1&_rdr',
					isURL: true,
				},
				{
					text: { en: 'YouTube', fr: 'Facebook' },
					link: 'https://www.youtube.com/@sotmafrica',
					isURL: true,
				},
			],
		},
		{
			title: {
				en: 'Contact us',
				fr: 'Contactez nous',
			},
			isActive: true,
			links: [
				{
					text: { en: 'Email', fr: 'Email' },
					link: 'contact@stateofthemap.africa',
					isURL: false,
					isEmail: true,
				},
				{
					text: { en: 'Wiki page', fr: 'Wiki page' },
					link: 'https://wiki.openstreetmap.org/wiki/State_of_the_Map_Africa_2025',
					isURL: true,
				},
				{
					text: {
						en: 'OSM Africa',
						fr: 'OSM Afrique',
					},
					link: '',
					isURL: false,
				},
				{
					text: {
						en: 'Code of conduct',
						fr: 'Code de conduite',
					},
					link: '/code-of-conduct',
					isURL: false,
				},
			],
		},
	],
	footerLogoURL: '/',
	copyright: {
		en: '© 2025 Technical and Communications Committee · SotM Africa',
		fr: '© 2025 Commission technique et de la communication - SotM Afrique',
	},

	// Pages

	//About
	About: {
		title: {
			en: 'About SotM Africa',
			fr: 'A propos de SotM Afrique',
		},
		picture: participants,
	},

	//Sponsors
	Sponsor: {
		title: {
			en: 'SotM Africa Sponsorship',
			fr: 'Sponsoring du SotM Afrique',
		},
		picture: participants,
	},

	//Code of Conduct

	CodeOfConduct: {
		title: {
			en: 'SotM Africa Code of Conduct',
			fr: 'Code de conduite de SotM Afrique',
		},
		picture: participants,
	},
	//Programme
	Programme: {
		title: {
			en: 'SotM Africa 2025 Programme',
			fr: 'Programme SotM Afrique 2025',
		},
		picture: participants,
	},
	// Calls

	Calls: {
		title: {
			en: 'Call for Participation',
			fr: "Appel à manifestation d'intérêt",
		},
		picture: participants,
	},

	//Venue
	Venue: {
		title: {
			en: 'Explore Dar es Salaam',
			fr: 'Visiter Dar es Salaam',
		},
		picture: venue1,
	},

	//Blog

	blogs: {
		fr: [
			{
				title: "La conférence sur l'état de la carte 2025 se tiendra à Dar es Salaam, en Tanzanie",
				date: '11 Février 2025',
				image: venue2,
				route: '/blog/sotm-venue-announcement',
				preview: `Du 28 au 30th novembre, 2025, la communauté OSM Afrique se réunira pour une autre conférence passionnante : State of the Map Africa 2025. Cette conférence vise à rassembler les passionnés d'OpenStreetMap d'Afrique et d'ailleurs...`,
			},
		],
		en: [
			{
				title: 'State of the Map Africa 2025 will be held in Dar es Salaam, Tanzania',
				date: 'February 11, 2025',
				image: venue2,
				route: '/blog/sotm-venue-announcement',
				preview: `From  28 - 30th November, 2025, the OSM Africa community will be coming together for yet another exciting conference: State of the Map Africa 2025. The conference aims to bring together OpenStreetMap enthusiasts from Africa and beyond...`,
			},
		],

	},

	//page contents

	contentfilePathMapping: {
		en: {
			about: AboutMdEn,
			sponsor: SponsorEn,
			calls: CallsEn,
			'code-of-conduct': CodeOfConductEn,
			venue: VenueEn,
			'sotm-venue-announcement': BlogVenueAnnouncement,
		},
		fr: {
			about: AboutMdFr,
			sponsor: SponsorFr,
			calls: CallsFr,
			'code-of-conduct': CodeOfConductFr,
			venue: VenueFr,
			'sotm-venue-announcement': BlogVenueAnnouncementFr,
		},
	},
};
